import dayjs from 'dayjs';
export const MONTH_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
export const DASHBOARD_OPTIONS = {
  app: 'App',
  stc: 'STC',
  employee: "Employee's",
  partner: "Partner's",
  cfa1_2024: 'CFA Level 1',
  cfa1_2025: 'CFA Level 1 2025 ',
  cfa2: 'CFA Level 2',
  cfa2_2025: 'CFA Level 2 2025 ',
  cfa3: 'CFA Level 3',
  cfa3_2025: 'CFA Level 3 2025 ',
  esg: 'ESG',
  frm1: 'FRM Part I',
  frm2: 'FRM Part II',
  soa_p: 'Actuarial Probability',
  soa_fm: 'Actuarial Financial Mathematics',
  soa_ifm: 'Actuarial Investment and Financial Markets',
  gmat: 'GMAT',
  gmat_focus: 'GMAT FOCUS',
  ea: 'EA',
  user_tracking: 'User Tracking',
};

export const examOptions = [
  { value: '', label: 'Select Exam' },
  { value: 'cfa', label: 'CFA Level 1' },
  { value: 'cfa_2', label: 'CFA Level 2' },
  { value: 'cfa_3', label: 'CFA Level 3' },
  { value: 'esg', label: 'ESG' },
  { value: 'frm', label: 'FRM Part I' },
  { value: 'frm_2', label: 'FRM Part II' },
  { value: 'soa_p', label: 'Actuarial-P' },
  { value: 'soa_fm', label: 'Actuarial-FM' },
  { value: 'gmat_focus', label: 'GMAT FOCUS' },
  { value: 'ea', label: 'EA' },
];
export const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
//* CREATED NEW { MOMENTS } KEY'S STARTED HERE ACCORDING TO DAY JS
// export const defaultYearFormat = 'YYYY';
// export const currentYear = parseInt(dayjs().format(defaultYearFormat));
// export const dbAcceptableFormat = 'YYYY-MM-DD';
// export const defaultDatePickerFormat = 'yyyy-MM-dd';
// export const defaultCreatedDateFormat = 'LL';
// export const defaultCreatedDateFormatDayjs = 'MMMM D, YYYY ';

// export const standardDateTimeFormat = 'lll';
// export const todayDate = dayjs().format('YYYY-MM-DD');

//* CREATED NEW { DAY-JS } KEY'S STARTED HERE ACCORDING TO DAY JS
export const defaultYearFormat = 'YYYY';
export const currentYear = dayjs().format('YYYY'); // No need to parse or format the integer.
export const dbAcceptableFormat = 'YYYY-MM-DD';
export const defaultDatePickerFormat = 'YYYY-MM-DD'; // Updated for Day.js.
export const defaultCreatedDateFormat = 'MMMM D, YYYY'; // Updated for Day.js.
export const standardDateTimeFormat = 'MMM D, YYYY h:mm A'; // Format it manually.
export const todayDate = dayjs().format('YYYY-MM-DD');
export const SecondsToHHMMSS = (sec) => {
  const seconds = sec % 60;
  const minutes = Math.floor(sec / 60);
  const hours = Math.floor(sec / 3600);
  return `${hours}:${minutes}:${seconds}`;
};
export const appDomainUrl = 'https://app.analystprep.com';

// validation maxlength QUESTION
export const NAME_MAXLENGTH = 500;
export const QUESTION_CHOICE_MAXLENGTH = 500;
// validation maxlength NOTES
export const NOTES_NAME_MAXLENGTH = 500;
export const NOTES_POST_TITLE = 500;
// validation maxlength video
export const VIDEO_TITLE_MAXLENGTH = 500;
export const examObject = {
  'CFA Level 1': 'cfa',
  'CFA Level 1 2022': 'cfa_2022',
  'CFA Level 1 2023': 'cfa_2023',
  'CFA Level 2': 'cfa_2',
  'CFA Level 2 2022': 'cfa_2_2022',
  'CFA Level 2 2023': 'cfa_2_2023',
  'CFA Level 3': 'cfa_3',
};
export const CBTMockTableDataKey = [
  'id',
  'title',
  'url_id',
  'url_id_qq',
  'video_order',
  'mock_id',
  'free',
  'isActive',
];
export const dashboardCategoryOptions = [
  { value: 'app', label: 'App' },
  { value: 'stc', label: 'STC' },
  { value: 'employee', label: 'Employee' },
  { value: 'partner', label: 'Partner' },
  { value: 'cfa1_2024', label: 'CFA Level 1' },
  { value: 'cfa1_2025', label: 'CFA Level 1 2025' },
  { value: 'cfa2', label: 'CFA Level 2' },
  { value: 'cfa2_2025', label: 'CFA Level 2 2025' },
  { value: 'cfa3', label: 'CFA Level 3' },
  { value: 'cfa3_2025', label: 'CFA Level 3 2025' },
  { value: 'esg', label: 'ESG' },
  { value: 'frm1', label: 'FRM Part I' },
  { value: 'frm2', label: 'FRM Part II' },
  { value: 'soa_p', label: 'Actuarial - P' },
  { value: 'soa_fm', label: 'Actuarial - FM' },
  { value: 'gmat_focus', label: 'GMAT FOCUS' },
  { value: 'ea', label: 'EA' },
];
export const dashboardOtherCategoryOptions = [
  { value: 'employee', label: 'Dashboard' },
  { value: 'cfa1_2024', label: 'CFA Level 1' },
  { value: 'cfa1_2025', label: 'CFA Level 1 2025 ' },
  { value: 'cfa2', label: 'CFA Level 2' },
  { value: 'cfa2_2025', label: 'CFA Level 2 2025 ' },
  { value: 'cfa3', label: 'CFA Level 3' },
  { value: 'cfa3_2025', label: 'CFA Level 3 2025 ' },
  { value: 'esg', label: 'ESG' },
  { value: 'frm1', label: 'FRM Part I' },
  { value: 'frm2', label: 'FRM Part II' },
  { value: 'soa_p', label: 'Actuarial - P' },
  { value: 'soa_fm', label: 'Actuarial - FM' },
  { value: 'gmat', label: 'GMAT' },
  { value: 'ea', label: 'EA' },
];
export const statusOption = [
  { value: 1, label: 'Active', name: 'isActive' },
  { value: 0, label: 'Inactive', name: 'isActive' },
];
export const questionType = [
  { value: '1', label: 'Free', name: 'free' },
  { value: '0', label: 'Premium', name: 'free' },
];

export const questionRightChoice = [
  { value: 'choice_a', label: 'Choice A', name: 'right_choice' },
  { value: 'choice_b', label: 'Choice B', name: 'right_choice' },
  { value: 'choice_c', label: 'Choice C', name: 'right_choice' },
];
export const questionOrderOption = [
  { value: '1', label: '1', name: 'order_questions' },
  { value: '0', label: '0', name: 'order_questions' },
];

export const getChapterAPIEndpoint = {
  cfa_3: 'GET_ALL_CFA3_CHAPTERS',
  soa_p: 'GET_ALL_PROBABILITY_CHAPTERS',
};

export const DEFAULT_YEAR = '2024';

export const adminRoles = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  partner: 'partner',
};
export const mockTableData = {
  sessions: [
    { label: '#' },
    { label: 'Session Name' },
    { label: 'Created Date' },
    { label: 'Action' },
  ],
  pathway: [
    { label: '#' },
    { label: 'Pathway Name' },
    { label: 'Created Date' },
    { label: 'Action' },
  ],
  default: [
    { label: '#' },
    { label: 'Mock Id' },
    { label: 'Mock Name' },
    { label: 'PDF' },
    { label: 'Created Date' },
    { label: 'Action' },
  ],
};
export const PATHWAY_ROUTE = '/pathway';
export const SESSION_ROUTE = '/sessions';
export const ESSAY_ROUTE = '/essay';

export const MESSAGES = {
  CASE_ACTIVATE: 'Do you want to activate this case',
  CASE_DEACTIVATE: 'Do you want to deactivate this case',
  COMPUTER_BASED_TESTING: ' Computer-Based Testing ',
  COMPUTER_TESTING_SESSION: 'Sessions',
  COMPUTER_TESTING_PATHWAY: 'Pathway',
  COMPUTER_TESTING_MOCK_EXAMS: 'Mock Exams',
  CFA3_ESSAY_VIDEOS: 'CFA 3 Essay Cases Videos',
  CFA3_VIDEOS: 'CFA 3 Videos',
  CFA3_VIEW_ESSAY_VIDEOS: ' CFA3 View Essay Cases Videos',
  CFA3_VIEW_VIDEOS: ' CFA3 Videos View',
  CFA3_EDIT_ESSAY_VIDEOS: 'CFA 3 Essay Cases Edit Video',
  CFA3_EDIT_VIDEOS: 'CFA 3 Edit Video',
  CFA3_ADD_ESSAY_VIDEOS: 'CFA 3 Add New Essay Cases Video',
  CFA3_ADD_VIDEOS: 'CFA 3 Add New Video',
};
